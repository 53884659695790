import React from "react"
import { navigate } from "gatsby"

import { isBrowser } from '../functions/functions'

import { labels } from '../langs/langs'

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnersSlider from "../components/PartnersSlider";

const ThankYouPage = ({ location }) => {

    if (isBrowser()) {
        if (!location.state?.done) {
            navigate("/");
        }
        return (<></>)
    }

    const localeID = 'sk_SK';

    return (
        <Layout location={location}>
            <Seo title="Ďakujeme" />
            <div className='notFound concrete g thx'>
                <div className='content center'>
                    <div>
                        <h1>{labels[localeID]["THX"]}</h1>
                        <div className='textSection'>{labels[localeID]["THXC"]}</div>
                        <a className="aArow left" onClick={()=>navigate(-1)} style={{color:"#ED6E47"}}>
                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L2 6L7 11" stroke="#ED6E47" strokeWidth="1.5"></path>
                            </svg>
                            {labels[localeID]["BACK"]}
                        </a>
                    </div>
                </div>
            </div>
            <PartnersSlider localeID={localeID}/>
        </Layout>
    )
}

export default ThankYouPage
